<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" style="height:55px;"  src="../../assets/images/logo/logowhite.png" />
    </Header>
    <HeaderBg />

    <v-container style="min-height: 450px;display: flex;align-items: center;justify-content: center;" v-if="web3">
      <a-result
          status="success"
          :title="'Created NFT on ' + web3.chainName +'!'"
      >
        <template slot="subTitle">
          <a target="_blank" :href="web3.fullItem.webUrl + '/tx/' + txHash" style="color:#1890FF">TxHash: {{txHash}}</a>
        </template>
        <template #extra>
          <!--<router-link to="/meta/profile">-->
            <a-button key="console" class="btn-large" type="primary" @click="toProfile">
              Back to Home
            </a-button>
          <!--</router-link>-->
          <!--<a-button key="buy">-->
          <!--  Buy Again-->
          <!--</a-button>-->
        </template>
      </a-result>
    </v-container>
    <!-- End Header Area -->

    <!-- End Breadcrump Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../../components/meta/Header";
  import HeaderBg from "../../components/meta/HeaderBg";
  import Footer from "../../components/meta/Footer";
  import {mapState} from "vuex";

  export default {
    components: {
      Header,
      HeaderBg,
      Footer,
    },
    mounted() {
      this.txHash = this.$route.query.txHash
    },
    data() {
      return {
        txHash: ''
      };
    },
    computed: {
      ...mapState(['web3'])
    },
    methods: {
      toProfile() {
        this.$router.replace({path: '/'})
      }
    }
  };
</script>
